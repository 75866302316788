import React, { useEffect, useState } from "react";
// Redux
import { useSelector } from "react-redux";

// Internal Imports
import LeftNavBar from "../components/LeftNavBar";
import RightPane from "../components/RightPane";
import styles from "../assets/scss/home.module.scss";
import { appData, initialState } from "../utils/appData";
import Settings from "../components/core-components/settings/Settings";
import ChargingStation from "../components/core-components/charging-station/ChargingStation";
import HazardInfo from "../components/core-components/hazard-info/HazardInfo";
import ShelterVisualization from "../components/core-components/shelter-visualization/ShelterVisualization";
import FacilityInfo from "../components/core-components/facility-info/FacilityInfo";

export default function Home() {
  // Redux
  const { loading } = useSelector((state) => state.commonMapData);

  const [selectedMenu, setSelectedMenu] = useState(appData.navMenuItems);
  const [sideBarElement, setSideBarElement] = useState(null);
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    // Settings
    if (selectedMenu?.settings) {
      setPageData(initialState);
      setSideBarElement(<Settings />);
      // Charging Station
    } else if (selectedMenu?.chargingStation) {
      setPageData(initialState);
      setSideBarElement(<ChargingStation />);
      // Hazard Info
    } else if (selectedMenu?.hazardInfo) {
      setPageData(initialState);
      setSideBarElement(<HazardInfo />);
      // Shelter Visualization
    } else if (selectedMenu?.shelterVisualization) {
      setPageData(initialState);
      setSideBarElement(<ShelterVisualization />);
      // Facility Info
    } else if (selectedMenu?.facilityInfo) {
      setPageData(initialState);
      setSideBarElement(<FacilityInfo />);
      // Home
    } else {
      setPageData(initialState);
      setSideBarElement(null);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu]);

  return (
    <div className={styles.container}>
      <LeftNavBar
        selectedMenu={selectedMenu}
        setSelectedMenu={setSelectedMenu}
        className={loading && "noClick"}
      />
      <RightPane selectedMenu={selectedMenu} pageData={pageData}>
        {sideBarElement}
      </RightPane>
    </div>
  );
}
