import React, { useEffect, useState } from "react";
import { Modal, Spin } from "antd";
// Redux
import { useDispatch } from "react-redux";
import { saveSelectedCityLoc } from "../../redux/slices/commonMapDataSlice";
// Internal Imports
import styles from "./address-search.module.scss";
import noSearchImg from "../../assets/images/no-search.png";
import searchIcon from "../../assets/icons/search-icon.svg";
import candidatesIcon from "../../assets/icons/candidates.svg";
import selectionIcon from "../../assets/icons/selections.svg";
import Horizontal from "../common/horizontal-line/Horizontal";
import { apiFetch } from "../../utils/fetchData";
import CandidateList from "./candidate-list/CandidateList";
import jpText from "../../utils/locales/jp.json";
import { AddressTreeData } from "./tree-data/AddressTreeData";
import CloseButtonIcon from "../common/buttons/CloseButtonIcon";

export default function AddressSearchModal(props) {
  const dispatch = useDispatch();

  const { isOpen, setIsOpen } = props;

  const zenrinApiKey = process.env.REACT_APP_ZENRIN_API_KEY;
  const zenrinApi = process.env.REACT_APP_ZENRIN_API;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [addressList, setAddressList] = useState([]);
  const [hoveredIndex, setHoveredIndex] = useState(null);
  const [selectedCandidateIndex, setSelectedCandidateIndex] = useState(null);
  const [isAddressLoading, setIsAddressLoading] = useState(false);
  const [hasAddressSearch, setHasAddressSearch] = useState(false);
  const [addressErrorMessage, setAddressErrorMessage] = useState("");

  const handleAddressSubmit = async (event) => {
    event.preventDefault();

    const formData = new FormData(event.target);
    const address = formData.get("address");

    if (address.length > 0 && zenrinApi && zenrinApiKey) {
      setIsAddressLoading(true);
      const url = `${zenrinApi}/zips/general/address?&zis_authtype=ip&zis_authkey=${zenrinApiKey}&word=${address}&limit=0,50`;
      try {
        const addressList = await apiFetch("GET", url);
        if (addressList?.result) {
          setAddressList(addressList.result.item);
          setHasAddressSearch(true);
          if (addressList.result.item?.length === 0) {
            setAddressErrorMessage(jpText.NO_CANDIDATE);
          }
        } else {
          setAddressErrorMessage(jpText.NO_CANDIDATE);
        }
        setIsAddressLoading(false);
      } catch (err) {
        setAddressList([]);
        setAddressErrorMessage(jpText.NO_CANDIDATE);
        setIsAddressLoading(false);
      }
    } else {
      setHasAddressSearch(true);
      setAddressErrorMessage(jpText.PLEASE_INPUT_SOMETHING_TO_PROCEED);
    }
  };

  const handleSelectedCandidate = (candidate, index) => {
    setSelectedCandidateIndex(index);

    if (candidate?.position[1] && candidate?.position[0]) {
      dispatch(
        saveSelectedCityLoc({
          selectedCityLoc: {
            lat: candidate?.position[1],
            lng: candidate?.position[0],
          },
        })
      );
    }

    setIsOpen(false);
  };

  useEffect(() => {
    setIsModalOpen(isOpen);
  }, [isOpen]);

  return (
    <>
      <Modal
        open={isModalOpen}
        onCancel={setIsOpen}
        footer={false}
        closeIcon={false}
        style={{ top: 20 }}
        width="395px"
      >
        <div className={styles.container}>
          <div className={styles.sectionTitleArea}>
            <div className={styles.sectionTitle}>
              <div>{jpText.ADDRESS_MODAL_TITLE}</div>
              <CloseButtonIcon onClick={() => setIsOpen(false)} />
            </div>

            <div className={styles.hrLine}>
              <Horizontal />
            </div>
          </div>
          {/* Search Input */}
          <div className={styles.searchSection}>
            <div className={styles.searchContainer}>
              <img
                className={styles.searchIcon}
                src={searchIcon}
                alt="search"
              />
              <form className={styles.formInput} onSubmit={handleAddressSubmit}>
                <input
                  disabled={isAddressLoading}
                  className={styles.searchInput}
                  placeholder={jpText.SEARCH_AREA}
                  maxLength={255}
                  name="address"
                />
              </form>
            </div>
          </div>

          {/* Candidates */}
          <div className={styles.candidateContainer}>
            <div className={styles.candidateTitleSec}>
              <img src={candidatesIcon} alt="candidate" />
              <div>{jpText.CANDIDATE}</div>
            </div>

            {isAddressLoading ? (
              <div className={styles.addressLoaderSec}>
                <Spin />
              </div>
            ) : hasAddressSearch ? (
              <div className={styles.candidateItems}>
                <CandidateList
                  addressList={addressList}
                  hoveredIndex={hoveredIndex}
                  setHoveredIndex={setHoveredIndex}
                  selectedCandidateIndex={selectedCandidateIndex}
                  handleSelectedCandidate={handleSelectedCandidate}
                  hasAddressSearch={hasAddressSearch}
                  addressErrorMessage={addressErrorMessage}
                />
              </div>
            ) : (
              <div className={styles.noSearchSec}>
                <img src={noSearchImg} alt="not searched" />
                <div className={styles.noSearchTitleSec}>
                  <div className={styles.noSearchTitle}>
                    {jpText.NOT_SEARCHED_YET}
                  </div>
                  <div className={styles.noSearchSubTitle}>
                    {jpText.PLEASE_SEARCH_THE_AREA_TO_VIEW_CANDIDATES}
                  </div>
                </div>
              </div>
            )}
          </div>

          {/* Selection */}
          <div className={styles.selectionContainer}>
            <div className={styles.selectionTitleSec}>
              <img src={selectionIcon} alt="candidate" />
              <div>{jpText.SELECTION}</div>
            </div>

            <div className={styles.selectionItems}>
              <AddressTreeData setIsOpen={setIsOpen} />
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
