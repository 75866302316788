import React, { useState } from "react";

import styles from "./close-btn-icon.module.scss";
import { closeCircleOutline } from "../../../utils/appIcons";

export default function CloseButtonIcon({ onClick }) {
  const [color, setColor] = useState("#727C8D");
  return (
    <div
      className={styles.container}
      onMouseEnter={() => setColor("#ff7f7f")}
      onMouseLeave={() => setColor("#727C8D")}
      onClick={onClick}
    >
      {closeCircleOutline(color)}
    </div>
  );
}
