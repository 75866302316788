import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  markerData: null,
};

export const clickedMarkerDataSlice = createSlice({
  name: "clickedMarkerData",
  initialState,
  reducers: {
    saveClickedMarkerData: (state, action) => {
      const { markerData } = action.payload;
      return {
        ...state,
        markerData: markerData,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const { saveClickedMarkerData } = clickedMarkerDataSlice.actions;

export default clickedMarkerDataSlice.reducer;
