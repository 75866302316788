import { appData, chargingTypes } from "../utils/appData";
import { useDispatch } from "react-redux";
import { saveStationList } from "../redux/slices/chargingStationSlice";
import { apiFetch } from "../utils/fetchData";
import { saveLoadingStateData } from "../redux/slices/commonMapDataSlice";

export default function useChargingStationFunctions() {
  const authKey = process.env.REACT_APP_ZENRIN_API_KEY;
  const zenrinApi = process.env.REACT_APP_ZENRIN_API;
  const meshSizeInKm = process.env.REACT_APP_CHARGING_STATION_MESH_SIZE;
  const dispatch = useDispatch();

  // Get Charging Station List
  // TODO: Can be deleted
  const getChargingStationList = async (selectedItems) => {
    const selectedIndices = [];

    // Iterate through the selected items
    for (const selectedItem of selectedItems) {
      const selectedType = chargingTypes.filter(
        (type) => type.label === selectedItem.value
      );
      if (selectedType && selectedType?.length > 0) {
        selectedIndices.push(selectedType[0]?.key);
      }
    }
    // Convert the array of indices to a comma-separated string
    const formattedIndices = selectedIndices.join(",");

    try {
      const data = {
        zis_authtype: "ip",
        zis_authkey: authKey,
        charge_type: formattedIndices,
      };

      const responseData = await chargingStationQueryRecursion(data, 0, 0, []);

      if (responseData && responseData?.length > 0) {
        dispatch(saveStationList({ stationList: responseData }));
      }
    } catch (error) {
      console.log("Error: ", error);
    }
  };
  // TODO: Can be deleted
  const chargingStationQueryRecursion = async (
    data,
    offset,
    totalItems,
    allItems
  ) => {
    data.limit = `${offset},1000`;
    // let itemCount = 0;
    let newOffset = 0;

    const url = `${zenrinApi}/zips/general/search/charging_station`;

    try {
      const response = await apiFetch("GET", url, data);

      if (response?.result?.info?.hit > 0) {
        // Update totalItems and offset
        totalItems = response.result.info.hit;
        newOffset = offset + 1000;
      }

      if (response?.result?.item?.length > 0) {
        let stationList = [];
        response.result.item.forEach((station) => {
          const {
            name,
            address,
            business_hours,
            charge_types,
            connector_num,
            billing_information,
            position,
          } = station;

          let stationData = {
            name,
            address,
            business_hours,
            charge_types,
            connector_num,
            billing_information,
            position,
          };
          stationList.push(stationData);
        });

        allItems.push(...stationList);
      }

      // Recurse only if offset is within bounds
      if (newOffset <= totalItems) {
        return chargingStationQueryRecursion(
          data,
          newOffset,
          totalItems,
          allItems
        );
      } else {
        return allItems;
      }
    } catch (error) {
      return allItems;
    }
  };

  const getChargingStationListF2 = async (selectedItems, meshGrid) => {
    const selectedIndices = [],
      allItems = [];

    // Iterate through the selected items
    for (const selectedItem of selectedItems) {
      const selectedType = chargingTypes.filter(
        (type) => type.label === selectedItem.value
      );
      if (selectedType && selectedType?.length > 0) {
        selectedIndices.push(selectedType[0]?.key);
      }
    }
    // Convert the array of indices to a comma-separated string
    const formattedIndices = selectedIndices.join(",");
    const url = `${zenrinApi}/zips/general/search/charging_station`;
    const data = {
      zis_authtype: "ip",
      zis_authkey: authKey,
      charge_type: formattedIndices,
      limit: "0,1000",
    };

    if (meshGrid?.length > 0) {
      dispatch(saveLoadingStateData({ loading: true }));
      for (const mesh of meshGrid) {
        try {
          const response = await fetchData(mesh, url, data);
          // console.log("response >> ", response);
          if (response?.result?.item?.length > 0) {
            let stationList = [];
            response.result.item.forEach((station) => {
              const {
                name,
                address,
                business_hours,
                charge_types,
                connector_num,
                billing_information,
                position,
              } = station;

              let stationData = {
                name,
                address,
                business_hours,
                charge_types,
                connector_num,
                billing_information,
                position,
              };
              stationList.push(stationData);
            });

            allItems.push(...stationList);
          }
        } catch (error) {
          // Handle errors (e.g., log them or skip this item)
          dispatch(saveLoadingStateData({ loading: false }));
        }
      }
    } else {
      dispatch(saveLoadingStateData({ loading: false }));
    }

    // console.log("allItems >> ", allItems);

    if (allItems && allItems?.length > 0) {
      dispatch(saveStationList({ stationList: allItems }));
    }
  };

  const fetchData = async (mesh, url, data) => {
    data.bbox = `${mesh[0].lng},${mesh[0].lat},${mesh[2].lng},${mesh[2].lat}`;
    try {
      // Make your API call here (using fetch, axios, or any other library)
      const response = await apiFetch("GET", url, data);
      return response; // Return the API response
    } catch (error) {
      console.error("Error fetching data:", error);
      dispatch(saveLoadingStateData({ loading: false }));
      throw error; // Rethrow the error or handle it as needed
    }
  };

  const setChargingStationDetails = (setStationDetails, clickedMarkerData) => {
    const chargeTypes = clickedMarkerData?.charge_types?.split(";");
    const connectorNum = clickedMarkerData?.connector_num?.split(";");
    const businessHours = clickedMarkerData?.business_hours?.split(";");
    const billingInfo = clickedMarkerData?.billing_information?.split(";");
    const connectorNumStrings = [];
    const chargingTypeNames = [];

    chargingTypes.forEach((typeItem, index) => {
      const chargerKeys = typeItem.key.split(",");
      let isExist = false;
      chargerKeys.forEach((keyStr) => {
        if (chargeTypes.some((str) => str.includes(keyStr))) {
          isExist = true;
        }
      });

      if (isExist) {
        chargingTypeNames.push(chargingTypes[index].label);
      }
    });

    connectorNum.forEach((connectorNumItem, index) => {
      connectorNumStrings.push(
        `${chargingTypeNames[index]}${connectorNumItem}台`
      );
    });

    setStationDetails({
      title: clickedMarkerData.name || "---", // Name
      items: [
        {
          label: "住所", // Address
          values: [clickedMarkerData.address],
        },
        {
          label: "充電方法", // Charging Method
          values: [chargingTypeNames.join("/")],
        },
        {
          label: "営業時間", // Business Hours
          values: businessHours || [""],
        },
        {
          label: "充電スタンド数", // Charging Stand Count
          values: [connectorNumStrings.join("/")],
        },
        {
          label: "備考", // Remarks
          values: billingInfo || [""],
        },
      ],
    });
  };

  const getMeshGrid = (latLngBounds) => {
    // console.log("latLngBounds >> ", latLngBounds);
    let japanBounds = { north: 46, south: 24, east: 154, west: 122 };

    const latStep = meshSizeInKm * appData.kmToDegree;
    const lngStep = meshSizeInKm * appData.kmToDegree;

    // const minLat =
    //   Math.floor((latLngBounds.southWest.lat - japanBounds.south) / latStep) *
    //     latStep +
    //   japanBounds.south -
    //   latStep * 2;

    // const maxLat =
    //   Math.ceil((latLngBounds.northEast.lat - japanBounds.south) / latStep) *
    //     latStep +
    //   japanBounds.south +
    //   latStep * 2;

    // const minLng =
    //   Math.floor((latLngBounds.southWest.lng - japanBounds.west) / lngStep) *
    //     lngStep +
    //   japanBounds.west -
    //   lngStep * 2;

    // const maxLng =
    //   Math.ceil((latLngBounds.northEast.lng - japanBounds.west) / lngStep) *
    //     lngStep +
    //   japanBounds.west +
    //   lngStep * 2;

    const minLat =
      Math.floor((latLngBounds.southWest.lat - japanBounds.south) / latStep) *
        latStep +
      japanBounds.south;
    const minLng =
      Math.floor((latLngBounds.southWest.lng - japanBounds.west) / lngStep) *
        lngStep +
      japanBounds.west;

    const maxLat =
      Math.ceil((latLngBounds.northEast.lat - japanBounds.south) / latStep) *
        latStep +
      japanBounds.south;
    const maxLng =
      Math.ceil((latLngBounds.northEast.lng - japanBounds.west) / lngStep) *
        lngStep +
      japanBounds.west;

    // console.log(
    //   "minLat/maxLat/minLng/maxLng >> ",
    //   minLat,
    //   maxLat,
    //   minLng,
    //   maxLng
    // );

    const rectangleCoords = [];

    for (let latStart = minLat; latStart < maxLat; latStart += latStep) {
      for (let lngStart = minLng; lngStart < maxLng; lngStart += lngStep) {
        let temp = [
          new window.ZDC.LatLng(latStart, lngStart),
          new window.ZDC.LatLng(latStart, lngStart + lngStep),
          new window.ZDC.LatLng(latStart + latStep, lngStart + lngStep),
          new window.ZDC.LatLng(latStart + latStep, lngStart),
        ];
        rectangleCoords.push(temp);
      }
    }
    // console.log("rectangleCoords >> ", rectangleCoords);
    return rectangleCoords;
  };

  return {
    getChargingStationList,
    setChargingStationDetails,
    getMeshGrid,
    getChargingStationListF2,
  };
}
