import React, { useEffect, useState } from "react";
import closeCircle from "../../../assets/icons/close-circle.svg";
import infoCircle from "../../../assets/icons/info-circle.svg";
import styles from "./error-message.module.scss";

const ErrorMessage = ({ message, addressList }) => {
  const [isVisible, setIsVisible] = useState(true);

  const handleClose = () => {
    setIsVisible(false);
  };

  useEffect(() => {
    if(addressList?.length === 0) {
        setIsVisible(true);
    }
  }, [addressList])

  return (
    <>
      {isVisible && (
        <div className={styles.ErrorMessage}>
          <div className={styles.ErrorMessageContent}>
            <img src={infoCircle} alt="error-message" />
            <p>{message}</p>
          </div>

          <img
            src={closeCircle}
            alt="error-message"
            onClick={handleClose}
            className={styles.CloseIcon}
          />
        </div>
      )}
    </>
  );
};

export default ErrorMessage;
