import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  stationList: [],
  chargingTypeColors: [],
  selectedChargingTypes: [],
};

export const chargingStationSlice = createSlice({
  name: "chargingStationData",
  initialState,
  reducers: {
    saveStationList: (state, action) => {
      const { stationList } = action.payload;
      return {
        ...state,
        stationList: stationList,
      };
    },
    saveChargingTypeColors: (state, action) => {
      const { chargingTypeColors } = action.payload;
      return {
        ...state,
        chargingTypeColors: chargingTypeColors,
      };
    },
    saveSelectedChargingTypes: (state, action) => {
      const { selectedChargingTypes } = action.payload;
      return {
        ...state,
        selectedChargingTypes: selectedChargingTypes,
      };
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  saveStationList,
  saveChargingTypeColors,
  saveSelectedChargingTypes,
} = chargingStationSlice.actions;

export default chargingStationSlice.reducer;
