export const removePrevListOfMarkers = async (map) => {
  if (map?.mrk_list_wg?.length > 0) {
    map.mrk_list_wg.forEach((item) => {
      map.removeWidget(item);
    });
  }
};
export const removePrevSelectedMarkerBg = (map) => {
  if (map?.marker_selected_bg) {
    map.removeWidget(map.marker_selected_bg);
  }
};
