const defaultLat = process.env.REACT_APP_DEFAULT_LAT;
const defaultLng = process.env.REACT_APP_DEFAULT_LNG;

export const appData = {
  defaultLatLng: {
    lat: defaultLat,
    lng: defaultLng,
  },
  defaultZoom: 15,
  navBarMenu: {
    home: "home",
    chargingStation: "chargingStation",
    shelterVisualization: "shelterVisualization",
    hazardInfo: "hazardInfo",
    facilityInfo: "facilityInfo",
    settings: "settings",
  },
  navMenuItems: {
    home: true,
    chargingStation: false,
    shelterVisualization: false,
    hazardInfo: false,
    facilityInfo: false,
    settings: false,
  },
  mapTypes: {
    colored: "kP8KjZdn",
    monochrome: "gZ6ujTVH",
  },
  kmToDegree: 0.008919, // 1 KM =  0.008919 Degree 
};

export const initialState = {
  // coordinates: [],
  // drawMarkers: false,
  // selectedCandidate: null,
  // // Regional Tree
  // treeData: [],
  // expandedKeys: [],
  // selections: [],
  // selectedTreeTitleStyle: [],
  // // Industry Types
  // selectedIndustries: [],
  // selectedIndustryIndexes: [],
  // industryColors: appData.industryColors,
  // // Region Select data
  // selectedRegionData: [],
  // selectedRegionTreeTitleStyle: [],
  // regionTreeCityCode: [],
};

export const extentChangeTypes = ["mapMove", "zoomIn", "zoomOut"];

export const defaultColors = [
  "#6c2df2",
  "#ea3680",
  "#c1e192",
  "#0dac8b",
  "#0ee526",
  "#88daf4",
  "#3e83a8",
  "#3946d1",
  "#01fbce",
  "#cc0607",
  "#421ea2",
  "#b94605",
  "#7959d7",
  "#e305f4",
  "#ed6d80",
  "#750105",
  "#fd5712",
  "#da852e",
  "#2d4512",
  "#fd9d92",
];

// Charging Station
export const chargingTypes = [
  { label: "急速充電", key: "1,2" }, // Fast Charging
  { label: "普通充電 200V (コンセントタイプ)", key: "3" }, // Normal charge 200V (Outlet type)
  { label: "普通充電 200V (ポールタイプ)", key: "4,5" }, // Normal charge 200V (Pole type)
  // {label:"普通充電 200V (ポールタイプ/Toyoda)", key: ""}, // Normal charge 200V (Pole type/Toyoda)
  { label: "普通充電 200V (形式不明)", key: "6" }, // Ordinary charge 200V (Format unknown)
  { label: "普通充電 100V (コンセントタイプ)", key: "7" }, // Normal charge 100V (outlet type)
];
