import React from "react";
// Internal Imports
import Map from "./Map";
import styles from "../assets/scss/right-pane.module.scss";

export default function RightPane(props) {
  const { selectedMenu, children, pageData } = props;

  return (
    <div className={styles.container}>
      {children && <div className={styles.sidebarContainer}>{children}</div>}
      <div className={styles.mapContainer}>
        <Map selectedMenu={selectedMenu} pageData={pageData} />
      </div>
    </div>
  );
}
