import { ADDRESS_LEVEL, PREFECTURE_CODES } from "../../../utils/addressCode";
import { appData } from "../../../utils/appData";
import jpText from "../../../utils/locales/jp.json";

export const TreeDataFunction = (
  addressCode,
  data,
  setTreeData,
  addressLevel,
  isItemEmpty
) => {
  if (addressLevel === ADDRESS_LEVEL.TBN) {
    let parentAddress3 = data.result.item[0]?.address3;
    let parentAddress4 = data.result.item[0]?.address4;
    let parentAddress5 = data.result.item[0]?.address5;
    const childrenData = data.result.item.map((item, index) => ({
      title: item?.address_detail2 || jpText.UNDEFINED,
      key: item.address_code,
      position: item.position,
      isLeaf: true,
      children: [
        {
          title:
            item?.address5 === null
              ? `${jpText.UNDEFINED}`
              : `${jpText.LOADING}...`,
          key: `0-0-0-0-0-0-${item.address_code}`,
          disabled: true,
          position: [appData.defaultLatLng.lng, appData.defaultLatLng.lat],
        },
      ],
    }));

    setTreeData((prevTreeData) => {
      return prevTreeData.map((node) => {
        if (node.title === jpText.NATIONAL) {
          const updatedChildren = node.children.map((cityNode) => {
            const lastIndex = cityNode.key.split("-").pop();
            const cityCodes = PREFECTURE_CODES[lastIndex];

            if (cityNode.title === cityCodes.value) {
              return {
                ...cityNode,
                children: cityNode.children.map((addressNode) => {
                  if (addressNode.title === parentAddress3) {
                    return {
                      ...addressNode,
                      children: addressNode.children.map((addressNode4) => {
                        if (addressNode4.title === parentAddress4) {
                          return {
                            ...addressNode4,
                            children: addressNode4.children.map(
                              (addressNode5) => {
                                if (
                                  addressNode5.title === parentAddress5 ||
                                  addressNode5.title === jpText.NO_BLOCK
                                ) {
                                  return {
                                    ...addressNode5,
                                    children: addressNode5.children.map(
                                      (addressNode6) => {
                                        if (
                                          addressNode6.title ===
                                          addressCode?.title
                                        ) {
                                          return {
                                            ...addressNode6,
                                            children:
                                              childrenData.length > 0
                                                ? childrenData
                                                : [
                                                    {
                                                      title: `${jpText.NO_DATA_FOUND}`,
                                                      key: `0-0-0-0-0-${addressNode6.key}-0`, // Demo key
                                                      disabled: true,
                                                      position: [
                                                        appData.defaultLatLng
                                                          .lng,
                                                        appData.defaultLatLng
                                                          .lat,
                                                      ],
                                                    },
                                                  ],
                                          };
                                        }
                                        return addressNode6;
                                      }
                                    ),
                                  };
                                }
                                return addressNode5;
                              }
                            ),
                          };
                        }
                        return addressNode4;
                      }),
                    };
                  }
                  return addressNode;
                }),
              };
            }
            return cityNode;
          });

          return {
            ...node,
            children: updatedChildren,
          };
        }
        return node;
      });
    });
  } else if (addressLevel === ADDRESS_LEVEL.GIK) {
    let parentAddress3 = data.result.item[0]?.address3;
    let parentAddress4 = data.result.item[0]?.address4;
    const childrenData = data.result.item.map((item, index) => ({
      title: item?.address_detail1 || jpText.NO_BLOCK_GIK,
      key: item.address_code,
      position: item.position,
      children: [
        {
          title:
            item?.address5 === null
              ? `${jpText.UNDEFINED}`
              : `${jpText.LOADING}...`,
          key: `0-0-0-0-0-0-${item.address_code}`,
          disabled: true,
          position: [appData.defaultLatLng.lng, appData.defaultLatLng.lat],
        },
      ],
    }));

    setTreeData((prevTreeData) => {
      return prevTreeData.map((node) => {
        if (node.title === jpText.NATIONAL) {
          const updatedChildren = node.children.map((cityNode) => {
            const lastIndex = cityNode.key.split("-").pop();
            const cityCodes = PREFECTURE_CODES[lastIndex];

            if (cityNode.title === cityCodes.value) {
              return {
                ...cityNode,
                children: cityNode.children.map((addressNode) => {
                  if (addressNode.title === parentAddress3) {
                    return {
                      ...addressNode,
                      children: addressNode.children.map((addressNode4) => {
                        if (addressNode4.title === parentAddress4) {
                          return {
                            ...addressNode4,
                            children: addressNode4.children.map(
                              (addressNode5) => {
                                if (addressNode5.title === addressCode?.title) {
                                  return {
                                    ...addressNode5,
                                    children:
                                      childrenData.length > 0
                                        ? childrenData
                                        : [
                                            {
                                              title: `${jpText.NO_DATA_FOUND}`,
                                              key: `0-0-0-0-${addressNode5.key}-0`, // Demo key
                                              disabled: true,
                                              position: [
                                                appData.defaultLatLng.lng,
                                                appData.defaultLatLng.lat,
                                              ],
                                            },
                                          ],
                                  };
                                }
                                return addressNode5;
                              }
                            ),
                          };
                        }
                        return addressNode4;
                      }),
                    };
                  }
                  return addressNode;
                }),
              };
            }
            return cityNode;
          });

          return {
            ...node,
            children: updatedChildren,
          };
        }
        return node;
      });
    });
  } else if (addressLevel === ADDRESS_LEVEL.AZC) {
    let parentAddress = data.result.item[0]?.address3;
    const childrenData = data.result.item.map((item, index) => ({
      title: item?.address5 || jpText.NO_BLOCK,
      key: item.address_code,
      position: item.position,
      children: [
        {
          title:
            item?.address5 === null
              ? `${jpText.UNDEFINED}`
              : `${jpText.LOADING}...`,
          key: `0-0-0-0-${item.address_code}-0`,
          disabled: true,
          position: [appData.defaultLatLng.lng, appData.defaultLatLng.lat],
        },
      ],
    }));

    setTreeData((prevTreeData) => {
      return prevTreeData.map((node) => {
        if (node.title === jpText.NATIONAL) {
          const updatedChildren = node.children.map((cityNode) => {
            const lastIndex = cityNode.key.split("-").pop();
            const cityCodes = PREFECTURE_CODES[lastIndex];

            if (cityNode.title === cityCodes.value) {
              return {
                ...cityNode,
                children: cityNode.children.map((addressNode) => {
                  if (addressNode.title === parentAddress) {
                    return {
                      ...addressNode,
                      children: addressNode.children.map((addressNode4) => {
                        if (addressNode4.title === addressCode.title) {
                          return {
                            ...addressNode4,
                            children:
                              childrenData.length > 0
                                ? childrenData
                                : [
                                    {
                                      title: `${jpText.NO_DATA_FOUND}`,
                                      key: `0-0-0-${addressNode4.key}-0`, // Demo key
                                      disabled: true,
                                      position: [
                                        appData.defaultLatLng.lng,
                                        appData.defaultLatLng.lat,
                                      ],
                                    },
                                  ],
                          };
                        }
                        return addressNode4;
                      }),
                    };
                  }
                  return addressNode;
                }),
              };
            }
            return cityNode;
          });

          return {
            ...node,
            children: updatedChildren,
          };
        }
        return node;
      });
    });
  } else if (addressLevel === ADDRESS_LEVEL.OAZ) {
    const childrenData = data.result.item.map((item, index) => ({
      title: item.address4 || jpText.NO_CHOME,
      key: item.address_code,
      position: item.position,
      children: [
        {
          title:
            item?.address5 === null
              ? `${jpText.UNDEFINED}`
              : `${jpText.LOADING}...`,
          key: `0-0-0-0-${item.address_code}`,
          disabled: true,
          position: [appData.defaultLatLng.lng, appData.defaultLatLng.lat],
        },
      ],
    }));

    setTreeData((prevTreeData) => {
      return prevTreeData.map((node) => {
        if (node.title === jpText.NATIONAL) {
          const updatedChildren = node.children.map((cityNode) => {
            const lastIndex = cityNode.key.split("-").pop();
            const cityCodes = PREFECTURE_CODES[lastIndex];

            if (cityNode.title === cityCodes.value) {
              return {
                ...cityNode,
                children: cityNode.children.map((addressNode) => {
                  if (addressNode.title === addressCode.title) {
                    return {
                      ...addressNode,
                      children:
                        childrenData.length > 0
                          ? childrenData
                          : [
                              {
                                title: `${jpText.NO_DATA_FOUND}`,
                                key: `0-0-0-${addressNode.key}-0`, // Demo key
                                disabled: true,
                                position: [
                                  appData.defaultLatLng.lng,
                                  appData.defaultLatLng.lat,
                                ],
                              },
                            ],
                    };
                  }
                  return addressNode;
                }),
              };
            }
            return cityNode;
          });

          return {
            ...node,
            children: updatedChildren,
          };
        }
        return node;
      });
    });
  }
};
