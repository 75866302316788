export const filterMarkersInsideLatLngBounds = (bounds, stationList) => {
  let listInsideViewPort = [];
  stationList.forEach((station) => {
    if (
      station.position[1] >= bounds?.southWest?.lat &&
      station.position[0] >= bounds?.southWest?.lng &&
      station.position[1] <= bounds?.northEast?.lat &&
      station.position[0] <= bounds?.northEast?.lng
    ) {
      listInsideViewPort.push(station);
    }
  });

  return listInsideViewPort;
};
