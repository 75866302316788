import React from "react";
import styles from "./section-title.module.scss";

export default function SectionTitle({ title, icon, borderBottom, onClick }) {
  return (
    <div
      className={`${styles.searchSection} ${
        borderBottom && styles.borderBottom
      }`}
      onClick={() => {
        onClick && onClick();
      }}
    >
      <div className="sectionTitle">{title}</div>
      <img src={icon} alt="search area" />
    </div>
  );
}
