import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  toggleCompassDisplay,
  toggleCenterPosition,
} from "../../../redux/slices/toggleButtonSlice";
import { saveSelectedMapType } from "../../../redux/slices/commonMapDataSlice";
import styles from "./settings.module.scss";
// import settingsIcon from "../../../assets/icons/setting.svg";
import jpText from "../../../utils/locales/jp.json";
import SectionLayout from "../../common/section-layout/SectionLayout";
import Horizontal from "../../common/horizontal-line/Horizontal";
import ToggleSwitchButton from "../../common/toggle-switch-button/ToggleSwitchButton";

export default function Settings() {
  const dispatch = useDispatch();

  const isCompassDisplayToggled = useSelector(
    (state) => state.toggleButtons.isCompassDisplayToggled
  );
  const isCenterPositionToggled = useSelector(
    (state) => state.toggleButtons.isCenterPositionToggled
  );
  const isMonochrome = useSelector((state) => state.commonMapData.isMonochrome);

  return (
    <div className={styles.container}>
      <SectionLayout>
        <div className={styles.heading}>
          <div className={`${styles.title} sectionTitle`}>
            {jpText.MAP_SETTINGS}
          </div>
          {/* <img src={settingsIcon} alt={jpText.MAP_SETTINGS} /> */}
        </div>
        <div className={styles.toggleButtons}>
          <div className={styles.toggleButtonCont}>
            <ToggleSwitchButton
              title={jpText.COMPASS_DISPLAY}
              onToggle={() => dispatch(toggleCompassDisplay())}
              isToggled={isCompassDisplayToggled}
            />
          </div>
          <Horizontal />
          <div className={styles.toggleButtonCont}>
            <ToggleSwitchButton
              title={jpText.CENTER_POSITION}
              onToggle={() => dispatch(toggleCenterPosition())}
              isToggled={isCenterPositionToggled}
            />
          </div>
          <Horizontal />
          <div className={styles.toggleButtonCont}>
            <ToggleSwitchButton
              title={jpText.CHANGE_MAP_TYPE}
              onToggle={() => dispatch(saveSelectedMapType())}
              isToggled={isMonochrome}
            />
          </div>
        </div>
      </SectionLayout>
    </div>
  );
}
