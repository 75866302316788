export const isAllDisplayInfoChecked = (list) => {
  let isAllChecked = true;
  if (list?.length > 0) {
    list.forEach((item) => {
      if (!item?.isChecked) {
        isAllChecked = false;
      }
    });
  }

  return isAllChecked;
};
