/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Internal imports
import styles from "./charging-station.module.scss";
import jpText from "../../../utils/locales/jp.json";
import SectionLayout from "../../common/section-layout/SectionLayout";
import SectionTitle from "../../common/left-pane-sec-title/SectionTitle";
import chargingStationIcon from "../../../assets/icons/charging-station.svg";
import chargingStationDetailsIcon from "../../../assets/icons/charging-station-details.svg";
import DropdownMultiSelect from "../../common/check-box/DropdownMultiSelect";
import { chargingTypes, defaultColors } from "../../../utils/appData";
import { locationMarker } from "../../../utils/appIcons";
import noDataFoundImg from "../../../assets/images/no-data-found.svg";
import NoDataComponent from "../../common/no-data-message/NoDataComponent";
import InfoDetails from "../../common/info-details/InfoDetails";
import useChargingStationFunctions from "../../../hooks/useChargingStationFunctions";
import {
  saveChargingTypeColors,
  saveSelectedChargingTypes,
  saveStationList,
} from "../../../redux/slices/chargingStationSlice";
import { saveClickedMarkerData } from "../../../redux/slices/clickedMarkerDataSlice";

export default function ChargingStation() {
  const dispatch = useDispatch();
  const clickedMarkerData = useSelector(
    (state) => state.clickedMarkerData.markerData
  );
  const { mapBounds, zoomLevel } = useSelector((state) => state.commonMapData);
  const { selectedChargingTypes } = useSelector(
    (state) => state.chargingStationData
  );
  const { loading } = useSelector((state) => state.commonMapData);
  console.log("Clicked Marker Data >> ", clickedMarkerData);
  const {
    // getChargingStationList,
    setChargingStationDetails,
    getMeshGrid,
    getChargingStationListF2,
  } = useChargingStationFunctions();

  const [chargerTypes, setChargerTypes] = useState([]);

  const [chargerTypeColorList, setChargerTypeColorList] = useState(null);
  const [noDataMessage, setNoDataMessage] = useState("");
  const [stationDetails, setStationDetails] = useState(null);
  const [viewPortMeshGrid, setViewPortMeshGrid] = useState([]);

  const checkboxSelectHandler = (list) => {
    dispatch(
      saveSelectedChargingTypes({
        selectedChargingTypes: list,
      })
    );

    // If selected charging types are changed, clear the selected station info
    dispatch(
      saveClickedMarkerData({
        markerData: null,
      })
    );
  };

  const colorChangeHandler = (color, index) => {
    const hexColor = color?.toHexString();
    let colorList = [...chargerTypeColorList];
    colorList[index] = hexColor;
    setChargerTypeColorList(colorList);
  };

  // ##################### UserEffect #####################

  useEffect(() => {
    if (chargingTypes?.length > 0) {
      const items = [];
      chargingTypes.forEach((item) => {
        items.push(item.label);
      });
      setChargerTypes(items);
    } else {
      setChargerTypes([]);
    }
  }, [chargingTypes]);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (mapBounds && zoomLevel >= 12) {
        const meshGrid = getMeshGrid(mapBounds);
        if (meshGrid?.length > 0) {
          setViewPortMeshGrid(meshGrid);
        } else {
          setViewPortMeshGrid([]);
        }
      } else {
        setViewPortMeshGrid([]);
      }
    }, 500);

    return () => clearTimeout(timer);
  }, [mapBounds?.northEast?.lat]);

  // Get new markers for charging station type change
  useEffect(() => {
    if (selectedChargingTypes?.length <= 0) {
      setNoDataMessage(jpText.SELECT_CHARGING_STATION_TEXT);
      dispatch(saveStationList({ stationList: [] }));
      dispatch(
        saveClickedMarkerData({
          markerData: null,
        })
      );
    } else if (
      selectedChargingTypes?.length > 0 &&
      viewPortMeshGrid?.length > 0
    ) {
      setNoDataMessage(jpText.CLICK_ON_CHARGING_STATION_ICON);
      // getChargingStationList(selectedItems);
      getChargingStationListF2(selectedChargingTypes, viewPortMeshGrid);
    } else {
      dispatch(saveStationList({ stationList: [] }));
      dispatch(
        saveClickedMarkerData({
          markerData: null,
        })
      );
    }
  }, [selectedChargingTypes, viewPortMeshGrid]);

  // update map marker color for checkbox color change
  useEffect(() => {
    if (chargerTypeColorList?.length > 0) {
      const list = [];
      chargingTypes?.forEach((type, index) => {
        list.push({ ...type, colorCode: chargerTypeColorList[index] });
      });
      dispatch(saveChargingTypeColors({ chargingTypeColors: list }));
    } else {
      setChargerTypeColorList(defaultColors);
    }
  }, [chargerTypeColorList]);

  // Set Station Details
  useEffect(() => {
    if (clickedMarkerData && Object.keys(clickedMarkerData)?.length > 0) {
      setChargingStationDetails(setStationDetails, clickedMarkerData);
    } else {
      setStationDetails(null);
    }
  }, [clickedMarkerData]);

  return (
    <div className={styles.container}>
      <SectionLayout>
        <SectionTitle
          title={jpText.CHARGING_STATION_INFORMATION}
          icon={chargingStationIcon}
          borderBottom={true}
        />

        <div className={styles.typeContainer}>
          <DropdownMultiSelect
            items={chargerTypes}
            selectedItems={selectedChargingTypes}
            onCheckboxSelect={checkboxSelectHandler}
            // setSelectedIndex={() => {}}
            hideSelectAll={true}
            icon={locationMarker}
            colorCodes={chargerTypeColorList}
            colorChangeHandler={colorChangeHandler}
            isDisabled={loading}
          />
        </div>
      </SectionLayout>

      {stationDetails === null && (
        <SectionLayout className={styles.noDataMsgContainer}>
          <NoDataComponent
            image={noDataFoundImg}
            message={noDataMessage}
            className={styles.noDataMsgSec}
          />
        </SectionLayout>
      )}

      {stationDetails && (
        <SectionLayout>
          <SectionTitle
            title={jpText.CHARGING_STATION_DETAILS}
            icon={chargingStationDetailsIcon}
            borderBottom={true}
          />
          <div className={styles.stationDetails}>
            <InfoDetails data={stationDetails} />
          </div>
        </SectionLayout>
      )}
    </div>
  );
}
