import React, { useState, useRef, useEffect } from "react";
import styles from "./dropdown.module.scss";
import dropdownIcon from "../../../assets/icons/dropdown.svg";
import tickIcon from "../../../assets/icons/tick.svg";

const Dropdown = ({ options, title }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const dropdownRef = useRef(null);

  const handleToggle = () => setIsOpen(!isOpen);

  const handleSelect = (option) => {
    setSelectedOption(option);
    setIsOpen(false);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className={styles.dropdown} ref={dropdownRef}>
      <p className={styles.dropdownTitle}>{title}</p>
      <div className={styles.dropdownArea} onClick={handleToggle}>
        <p>{selectedOption}</p>
        <div className={styles.dropdownIcons}>
          <div className={styles.verticalLine}></div>
          <img src={dropdownIcon} alt="dropdown" />
        </div>
      </div>
      {isOpen && (
        <ul className={styles.dropdownMenu}>
          {options.map((option, index) => (
            <li
              key={index}
              className={`${styles.dropdownItem} ${
                selectedOption === option ? styles.selectedOption : ""
              }`}
              onClick={(e) => {
                e.stopPropagation();
                handleSelect(option);
              }}
            >
              {option}
              {selectedOption === option && (
                <img src={tickIcon} alt="tick" className={styles.tickIcon} />
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
