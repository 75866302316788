import React, { useEffect, useState } from "react";
// Internal imports
import styles from "./shelter-visualization.module.scss";
import jpText from "../../../utils/locales/jp.json";
import SectionLayout from "../../common/section-layout/SectionLayout";
import SectionTitle from "../../common/left-pane-sec-title/SectionTitle";
import evacuationShelterIcon from "../../../assets/icons/shelter.svg";
import DropdownMultiSelect from "../../common/check-box/DropdownMultiSelect";
import NoDataComponent from "../../common/no-data-message/NoDataComponent";
import noDataFoundImg from "../../../assets/images/no-data-found.svg";
import evacuationLocationIcon from "../../../assets/icons/shelter-location.svg";
import evacuationShelterPurpleIcon from "../../../assets/icons/shelter-purple.svg";
import petFriendlyIcon from "../../../assets/icons/shelter-pet-friendly.svg";
import welfareIcon from "../../../assets/icons/shelter-welfare.svg";
import neighboringMunicipalIcon from "../../../assets/icons/shelter-neighboring.svg";
import chargingStationDetailsIcon from "../../../assets/icons/charging-station-details.svg";
import InfoDetails from "../../common/info-details/InfoDetails";
import ToggleSwitchButton from "../../common/toggle-switch-button/ToggleSwitchButton";
import Dropdown from "../../common/dropdown/dropdown";
import Horizontal from "../../common/horizontal-line/Horizontal";

export default function ShelterVisualization() {
  const [noDataMessage, setNoDataMessage] = useState("");
  const [isToggled, setIsToggled] = useState(false);

  const shelterInfo = [
    "避難場所",
    "避難所",
    "ペット同伴避難所",
    "福祉避難所",
    "近隣自治体避難所等",
  ];
  const [selectedItems, setSelectedItems] = useState([]);
  const [shelterDetails, setShelterDetails] = useState({
    title: "No.50 Yahata Elementary School",
    items: [
      {
        label: "Address",
        values: ["24-1 Yahata 3-chome"],
      },
      {
        label: "Telephone Number",
        values: ["047-xx-xxxx"],
      },
      {
        label: "Expected Flooding",
        values: ["Edogawa: ×", "Small and medium size:△", "Storm surge: ×"],
      },
      {
        label: "Capacity",
        values: ["100 people"],
      },
      {
        label: "Charging Stand",
        values: ["3 units"],
      },
    ],
  });
  const [surroundingDetails, setSurroundingDetails] = useState({
    title: jpText.SURROUNDING_INFORMATION,
    items: [
      {
        label: "Charging Stations",
        values: ["3 locations/number: 10"],
      },
      {
        label: "A.D.",
        values: ["Approximately 500 people"],
      },
    ],
  });
  const radius = ["500m","1km", "2km", "3km"];

  const checkboxSelectHandler = (list) => {
    setSelectedItems(list);
  };

  const handleToggle = () => {
    setIsToggled((prevState) => !prevState);
  };

  useEffect(() => {
    if (selectedItems?.length <= 0) {
      setNoDataMessage(jpText.SELECT_EVACUATION_SHELTER_TEXT);
    } else if (selectedItems?.length > 0) {
      setNoDataMessage(jpText.CLICK_ON_SHELTER_EVACUATION_ICON);
    }
  }, [selectedItems]);

  return (
    <div className={styles.container}>
      <SectionLayout>
        <SectionTitle
          title={jpText.EVACUATION_SHELTER_INFORMATION}
          icon={evacuationShelterIcon}
          borderBottom={true}
        />

        <div className={styles.typeContainer}>
          <DropdownMultiSelect
            items={shelterInfo}
            selectedItems={selectedItems}
            onCheckboxSelect={checkboxSelectHandler}
            hideSelectAll={true}
            iconArray={[
              evacuationLocationIcon,
              evacuationShelterPurpleIcon,
              petFriendlyIcon,
              welfareIcon,
              neighboringMunicipalIcon,
            ]}
          />
        </div>
      </SectionLayout>

      <SectionLayout>
        <div className={styles.typeContainer}>
          <Dropdown title={jpText.RADIUS} options={radius} />
          <div className={styles.lineSpacing}>
          <Horizontal className={styles.horizontal} />
          </div>
          <ToggleSwitchButton
            title={jpText.CIRCLE_DRAWING}
            isToggled={isToggled}
            onToggle={handleToggle}
          />
        </div>
      </SectionLayout>

      <SectionLayout>
        <NoDataComponent
          image={noDataFoundImg}
          message={noDataMessage}
          className={styles.noDataMsgSec}
        />
      </SectionLayout>

      <SectionLayout>
        <SectionTitle
          title={jpText.EVACUATION_SHELTER_DETAILS}
          icon={chargingStationDetailsIcon}
          borderBottom={true}
        />
        <div className={styles.shelterDetails}>
          <InfoDetails data={shelterDetails} />
        </div>
        <div className={styles.shelterDetails}>
          <InfoDetails data={surroundingDetails} />
        </div>
      </SectionLayout>
    </div>
  );
}
