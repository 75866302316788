import React from "react";
import styles from "./info-details.module.scss";

export default function InfoDetails({ data }) {
  if (!data || Object.keys(data)?.length <= 0) return;
  return (
    <table>
      <thead>
        <tr>
          <th className={styles.title} colSpan={2}>{data.title}</th>
        </tr>
      </thead>
      <tbody>
        {data?.items?.map((dataItem, index) =>
          dataItem?.values?.length === 1 ? (
            <tr key={index}>
              <td className={styles.bodyRowDataLeft}>{dataItem.label}</td>
              <td className={styles.bodyRowDataRight}>{dataItem.values[0]}</td>
            </tr>
          ) : (
            dataItem?.values?.length > 1 && (
              <React.Fragment key={index}>
                <tr>
                  <td
                    rowSpan={dataItem.values.length}
                    className={styles.bodyRowDataLeft}
                  >
                    {dataItem.label}
                  </td>
                  <td className={styles.bodyRowDataRight}>
                    {dataItem.values[0]}
                  </td>
                </tr>

                {dataItem?.values?.map(
                  (dataItemValue, innerIdx) =>
                    innerIdx > 0 && (
                      <tr key={innerIdx}>
                        <td className={styles.bodyRowDataRight}>
                          {dataItemValue}
                        </td>
                      </tr>
                    )
                )}
              </React.Fragment>
            )
          )
        )}
      </tbody>
    </table>
  );
}
