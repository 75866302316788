export const getCustomMarker = (color) => {
  return `<svg width="20" height="28" viewBox="0 0 20 28" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M10 0C5.5808 0 0 3.63756 0 8.1433C0 15.9341 10 26.0945 10 26.0945C10 26.0945 20 15.9325 20 8.1433C20 3.63919 14.4192 0 10 0ZM10 13.649C8.85426 13.649 7.75546 13.1848 6.9453 12.3585C6.13514 11.5322 5.68 10.4114 5.68 9.24284C5.68 8.07425 6.13514 6.95352 6.9453 6.1272C7.75546 5.30087 8.85426 4.83665 10 4.83665C11.1457 4.83665 12.2445 5.30087 13.0547 6.1272C13.8649 6.95352 14.32 8.07425 14.32 9.24284C14.32 10.4114 13.8649 11.5322 13.0547 12.3585C12.2445 13.1848 11.1457 13.649 10 13.649Z" fill="${color}"/>
  <ellipse opacity="0.5" cx="10" cy="26" rx="4" ry="2" fill="${color}"/>
  </svg>`;
};

export const getCustomMarkerSelectedBg = (color) => {
  return `<svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
  <circle opacity="0.3" cx="14.5" cy="14.5" r="14" fill="${color}"/>
  <circle opacity="0.2" cx="14.5" cy="14.5" r="10.8889" fill="${color}"/>
  </svg>`;
};
