import axios from "axios";

export async function apiFetch(method, url, data, contentType = null) {
  // Axios options
  const options = {
    url: url,
    method: method,
    headers: {
      "Content-Type": contentType
        ? contentType
        : "application/x-www-form-urlencoded",
    },
  };

  if (method && method.toLowerCase() === "get") {
    if (data) {
      if (Array.isArray(data)) {
        options.params = Object.assign({}, data);
      } else {
        options.params = data;
      }
    }
  } else {
    if (data) {
      options.data = data;
    }
  }

  let response;
  try {
    response = await axios(options);
    return response.data;
  } catch (err) {
    throw err.response.data;
  }
}
