import { Route, Routes, BrowserRouter } from "react-router-dom";
import "./App.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// Redux
import { Provider } from "react-redux";
import { store } from "./redux/store";
// Internal Imports
import Home from "./views/Home";

function App() {
  return (
    <div>
      <Provider store={store()}>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/mesh-search" element={<MeshSearch />} /> */}
          </Routes>
        </BrowserRouter>
        <ToastContainer autoClose={1500} />
      </Provider>
    </div>
  );
}

export default App;
