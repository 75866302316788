/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
// Redux
import {
  saveMapBounds,
  saveMapExtentChangeType,
  saveZoomLevel,
} from "../redux/slices/commonMapDataSlice";
// Internal Imports
import { appData, extentChangeTypes } from "../utils/appData";
import styles from "../assets/scss/map.module.scss";
import searchIcon from "../assets/icons/search-icon.svg";
import jpText from "../utils/locales/jp.json";
import { debounceMarker } from "../utils/debounceMarker";
import { saveLoadingStateData } from "../redux/slices/commonMapDataSlice";
import AddressSearchModal from "./address-search/AddressSearchModal";
import Loader from "./common/loader/loader";
import useDrawStationMarkers from "../hooks/useDrawMarkerList";
import {
  removePrevListOfMarkers,
  removePrevSelectedMarkerBg,
} from "../hooks/mapWidgetFunctions";

let { map } = window;
let mapOptions = {},
  mrk_widget,
  compass_widget;

export default function Map(props) {
  const { selectedMenu, pageData } = props;
  const [mapScript] = useState(document.querySelector('script[src*="loader"]'));
  // Redux
  const dispatch = useDispatch();
  const isCompassDisplayToggled = useSelector(
    (state) => state.toggleButtons.isCompassDisplayToggled
  );
  const isCenterPositionToggled = useSelector(
    (state) => state.toggleButtons.isCenterPositionToggled
  );
  const commonMapData = useSelector((state) => state.commonMapData);
  const { stationList, chargingTypeColors } = useSelector(
    (state) => state.chargingStationData
  );
  const { isMonochrome } = useSelector((state) => state.commonMapData);
  // console.log("stationList >> ", stationList);
  // hooks
  const { drawStationMarkers } = useDrawStationMarkers();

  const defaultLatLng = appData.defaultLatLng;
  const [zoomLevel, setZoomLevel] = useState(appData.defaultZoom);
  console.log("zoomLevel >> ", zoomLevel);
  const [latLngBounds, setLatLngBounds] = useState(null);
  // Address Search
  const [addressModal, setAddressModal] = useState(false);
  const [showAddressSearchBtn, setShowAddressSearchBtn] = useState(true);

  const [isHomeMenuSelected, setIsHomeMenuSelected] = useState(
    selectedMenu.home
  );

  const initMap = () => {
    mapScript.onload = () => {
      window.ZisAuth.addEventListener("ready", async () => {
        mapOptions = await window.ZisAuth.getMapOption();
        mapOptions.center = new window.ZDC.LatLng(
          defaultLatLng.lat,
          defaultLatLng.lng
        );
        mapOptions.zoom = appData.defaultZoom;
        mapOptions.centerZoom = true;
        mapOptions.minZoom = 3;
        mapOptions.mouseWheelReverseZoom = true;
        mapOptions.zipsMapType = appData.mapTypes.colored;
        map = new window.ZDC.Map(
          document.getElementById("ZMap"),
          mapOptions,
          function () {
            map.addControl(new window.ZDC.ZoomButton("top-left"));
            map.addControl(new window.ZDC.ZoomButton("top-left"));
            // Draw Compass
            drawCompass();
            map.addControl(new window.ZDC.ScaleBar("bottom-left"));
            // Marker at center position
            drawCenterMarker();

            dispatch(saveLoadingStateData({ loading: false }));
            getMapBounds();

            // Zoom Change
            map.addEventListener(
              "zoomstart",
              debounceMarker(function (event) {
                setZoomLevel(Math.floor(event.newZoom));
                dispatch(
                  saveZoomLevel({
                    zoomLevel: Math.floor(event.newZoom),
                  })
                );
                getMapBounds();

                if (event.currZoom < event.newZoom) {
                  dispatch(
                    saveMapExtentChangeType({
                      extentChangeType: extentChangeTypes[1],
                    })
                  );
                } else if (event.currZoom > event.newZoom) {
                  dispatch(
                    saveMapExtentChangeType({
                      extentChangeType: extentChangeTypes[2],
                    })
                  );
                }
              }, 300)
            );

            map.addEventListener("mouseup", function (event) {
              getMapBounds();
              dispatch(
                saveMapExtentChangeType({
                  extentChangeType: extentChangeTypes[0],
                })
              );
            });
            map.addEventListener("touchend", function (event) {
              getMapBounds();
              dispatch(
                saveMapExtentChangeType({
                  extentChangeType: extentChangeTypes[0],
                })
              );
            });
          },
          function () {
            // Failure callback
            console.log("--------initMap Failed--------- ");
          }
        );
      });
    };
  };

  const drawCenterMarker = () => {
    mrk_widget = new window.ZDC.CenterMarker();
    map.addControl(mrk_widget);
  };

  const drawCompass = () => {
    compass_widget = new window.ZDC.Compass("top-right");
    map.addControl(compass_widget);
  };

  const refreshMapSize = () => {
    if (map) {
      map.refreshSize();
    }
  };

  const reinitializeMap = () => {
    if (map) {
      const options = {
        zipsMapType: isMonochrome
          ? appData.mapTypes.monochrome
          : appData.mapTypes.colored,
      };

      const successFunc = () => {
        console.log("Map reinitialized successfully!");
      };

      const failFunc = (error) => {
        console.error("Map reinitialization failed:", error);
      };

      map.reinitialize(options, successFunc, failFunc);
    }
  };

  // Get Map Bounds
  const getMapBounds = () => {
    if (map) {
      try {
        const bounds = map?.getLatLngBounds();
        setLatLngBounds(bounds);
        dispatch(saveMapBounds({ mapBounds: bounds }));
      } catch (error) {
        console.log("LatLng Bound Error: ", error);
      }
    }
  };

  // ##################### UserEffect #####################

  // Init Map
  useEffect(() => {
    if (mapScript) {
      initMap();
      dispatch(saveLoadingStateData({ loading: true }));
    }
  }, [mapScript]);

  // Charging Station
  useEffect(() => {
    if (map && stationList?.length > 0) {
      // console.log("chargingTypeColors >> ", chargingTypeColors);
      drawStationMarkers(map, latLngBounds, stationList, chargingTypeColors);
    } else {
      removePrevListOfMarkers(map);
      removePrevSelectedMarkerBg(map);
    }
  }, [latLngBounds, stationList, chargingTypeColors]);

  // Re-center map based on City/Candidate selection from Home page
  useEffect(() => {
    if (map) {
      const { lat, lng } = commonMapData?.selectedCityLoc;
      if (lat && lng) {
        let latLngs = new window.ZDC.LatLng(lat, lng);
        map?.setCenter(latLngs);
      }
    }
  }, [commonMapData?.selectedCityLoc]);

  // For the initial screen only, show Address Search button
  useEffect(() => {
    if (selectedMenu?.home) {
      setShowAddressSearchBtn(true);
    } else {
      setShowAddressSearchBtn(false);
    }
  }, [selectedMenu]);

  // Update Center Plus Marker based on isCenterPositionToggled update
  useEffect(() => {
    if (map && mrk_widget) {
      if (isCenterPositionToggled) {
        drawCenterMarker();
      } else {
        map.removeControl(mrk_widget);
      }
    }
  }, [mrk_widget, isCenterPositionToggled]);

  // Update Compass based on isCompassDisplayToggled update
  useEffect(() => {
    if (map && compass_widget) {
      if (isCompassDisplayToggled) {
        drawCompass();
      } else {
        map.removeControl(compass_widget);
      }
    }
  }, [compass_widget, isCompassDisplayToggled]);

  // Refresh Map Size when right pie-chart pane is shown/hidden from Info Comparison page and selected region group count is changed
  useEffect(() => {
    refreshMapSize();
  }, [isHomeMenuSelected]);

  // Disable Zoom buttons while loading
  useEffect(() => {
    const zoomInIconElements = document.querySelector(
      ".zwgl-ctrl-icon.zwgl-ctrl-zoom-in"
    );
    const zoomOutIconElements = document.querySelector(
      ".zwgl-ctrl-icon.zwgl-ctrl-zoom-out"
    );

    if (zoomInIconElements) {
      if (commonMapData.loading) {
        zoomInIconElements.style.pointerEvents = "none";
      } else {
        zoomInIconElements.style.pointerEvents = "auto";
      }
    }

    if (zoomOutIconElements) {
      if (commonMapData.loading) {
        zoomOutIconElements.style.pointerEvents = "none";
      } else {
        zoomOutIconElements.style.pointerEvents = "auto";
      }
    }
  }, [commonMapData.loading]);

  useEffect(() => {
    reinitializeMap();
  }, [isMonochrome]);

  useEffect(() => {
    setIsHomeMenuSelected(selectedMenu.home);
  }, [selectedMenu.home]);

  return (
    <div
      className={`${styles.mapContainer} ${commonMapData.loading && "noClick"}`}
    >
      {commonMapData.loading && <Loader />}

      <div id="ZMap" className={`${styles.map}`} />

      {showAddressSearchBtn && !commonMapData.loading && (
        <div
          className={styles.addressBtnSec}
          onClick={() => setAddressModal(true)}
        >
          <img src={searchIcon} alt="search" />
          <button>{jpText.SEARCH_ADDRESS}</button>
        </div>
      )}

      {addressModal && (
        <AddressSearchModal isOpen={true} setIsOpen={setAddressModal} />
      )}
    </div>
  );
}
