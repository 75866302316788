import React from "react";
// Internal imports
import styles from "./facility-info.module.scss";
import jpText from "../../../utils/locales/jp.json";
import SectionLayout from "../../common/section-layout/SectionLayout";

export default function FacilityInfo() {
  return (
    <div className={styles.container}>
      <SectionLayout>In-Progress</SectionLayout>
    </div>
  );
}
