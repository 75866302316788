import React from "react";
import styles from "./no-data-msg.module.scss";

export default function NoDataComponent({ image, message, className }) {
  return (
    <div className={`${styles.noDataMsgSec} ${className}`}>
      <img src={image} alt="no-data" />
      <div className={styles.noDataMsg}>{message}</div>
    </div>
  );
}
