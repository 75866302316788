export const PREFECTURE_CODES = [
  { code: "01", addressKey: "0-0-0", value: "北海道" },
  { code: "02", addressKey: "0-0-1", value: "青森県" },
  { code: "03", addressKey: "0-0-2", value: "岩手県" },
  { code: "04", addressKey: "0-0-3", value: "宮城県" },
  { code: "05", addressKey: "0-0-4", value: "秋田県" },
  { code: "06", addressKey: "0-0-5", value: "山形県" },
  { code: "07", addressKey: "0-0-6", value: "福島県" },
  { code: "08", addressKey: "0-0-7", value: "茨城県" },
  { code: "09", addressKey: "0-0-8", value: "栃木県" },
  { code: "10", addressKey: "0-0-9", value: "群馬県" },
  { code: "11", addressKey: "0-0-10", value: "埼玉県" },
  { code: "12", addressKey: "0-0-11", value: "千葉県" },
  { code: "13", addressKey: "0-0-12", value: "東京都" },
  { code: "14", addressKey: "0-0-13", value: "神奈川県" },
  { code: "15", addressKey: "0-0-14", value: "新潟県" },
  { code: "16", addressKey: "0-0-15", value: "富山県" },
  { code: "17", addressKey: "0-0-16", value: "石川県" },
  { code: "18", addressKey: "0-0-17", value: "福井県" },
  { code: "19", addressKey: "0-0-18", value: "山梨県" },
  { code: "20", addressKey: "0-0-19", value: "長野県" },
  { code: "21", addressKey: "0-0-20", value: "岐阜県" },
  { code: "22", addressKey: "0-0-21", value: "静岡県" },
  { code: "23", addressKey: "0-0-22", value: "愛知県" },
  { code: "24", addressKey: "0-0-23", value: "三重県" },
  { code: "25", addressKey: "0-0-24", value: "滋賀県" },
  { code: "26", addressKey: "0-0-25", value: "京都府" },
  { code: "27", addressKey: "0-0-26", value: "大阪府" },
  { code: "28", addressKey: "0-0-27", value: "兵庫県" },
  { code: "29", addressKey: "0-0-28", value: "奈良県" },
  { code: "30", addressKey: "0-0-29", value: "和歌山県" },
  { code: "31", addressKey: "0-0-30", value: "鳥取県" },
  { code: "32", addressKey: "0-0-31", value: "島根県" },
  { code: "33", addressKey: "0-0-32", value: "岡山県" },
  { code: "34", addressKey: "0-0-33", value: "広島県" },
  { code: "35", addressKey: "0-0-34", value: "山口県" },
  { code: "36", addressKey: "0-0-35", value: "徳島県" },
  { code: "37", addressKey: "0-0-36", value: "香川県" },
  { code: "38", addressKey: "0-0-37", value: "愛媛県" },
  { code: "39", addressKey: "0-0-38", value: "高知県" },
  { code: "40", addressKey: "0-0-39", value: "福岡県" },
  { code: "41", addressKey: "0-0-40", value: "佐賀県" },
  { code: "42", addressKey: "0-0-41", value: "長崎県" },
  { code: "43", addressKey: "0-0-42", value: "熊本県" },
  { code: "44", addressKey: "0-0-43", value: "大分県" },
  { code: "45", addressKey: "0-0-44", value: "宮崎県" },
  { code: "46", addressKey: "0-0-45", value: "鹿児島県" },
  { code: "47", addressKey: "0-0-46", value: "沖縄県" },
];
export const ADDRESS_LEVEL = {
  SHK: "SHK",
  OAZ: "OAZ",
  AZC: "AZC",
  GIK: "GIK",
  TBN: "TBN",
  LENGTH_5: 5,
  LENGTH_8: 8,
  LENGTH_11: 11,
  LENGTH_16: 16,
};
