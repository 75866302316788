// Internal Imports
import candidateItem from "../../../assets/icons/candidate-item.svg";
import candidateItemHover from "../../../assets/icons/candidate-item-hover.svg";
import styles from "./candidate-list.module.scss";
import ErrorMessage from "../../common/error-message/ErrorMessage";

const CandidateList = ({
  addressList,
  hoveredIndex,
  setHoveredIndex,
  selectedCandidateIndex,
  handleSelectedCandidate,
  hasAddressSearch,
  addressErrorMessage,
}) => {
  if (addressList.length === 0 && hasAddressSearch) {
    return (
      <ErrorMessage message={addressErrorMessage} addressList={addressList} />
    );
  }

  return (
    <div className={styles.candidateList}>
      {addressList.map((candidate, index) => (
        <div
          key={index}
          className={
            selectedCandidateIndex === index
              ? styles.selectedCandidateItem
              : styles.candidateItem
          }
          onClick={() => handleSelectedCandidate(candidate, index)}
          onMouseEnter={() => setHoveredIndex(index)}
          onMouseLeave={() => setHoveredIndex(null)}
        >
          <img
            src={
              hoveredIndex === index || selectedCandidateIndex === index
                ? candidateItemHover
                : candidateItem
            }
            alt="candidate"
          />
          <div>{candidate.address}</div>
        </div>
      ))}
    </div>
  );
};

export default CandidateList;
