import { configureStore } from "@reduxjs/toolkit";
import commonMapDataReducer from "./slices/commonMapDataSlice";
import toggleButtonReducer from "./slices/toggleButtonSlice";
import clickedMarkerDataReducer from "./slices/clickedMarkerDataSlice";
import chargingStationReducer from "./slices/chargingStationSlice";

export const store = () =>
  configureStore({
    reducer: {
      commonMapData: commonMapDataReducer,
      toggleButtons: toggleButtonReducer,
      clickedMarkerData: clickedMarkerDataReducer,
      chargingStationData: chargingStationReducer,
    },
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: false,
      }),
  });
