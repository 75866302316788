import React, { useEffect, useState } from "react";
import { Tree } from "antd";
import { useDispatch } from "react-redux";
import jpText from "../../../utils/locales/jp.json";
import { appData } from "../../../utils/appData";
import { saveSelectedCityLoc } from "../../../redux/slices/commonMapDataSlice";
import { ADDRESS_LEVEL, PREFECTURE_CODES } from "../../../utils/addressCode";
import { TreeDataFunction } from "./TreeDataFunction";

export const AddressTreeData = ({ setIsOpen }) => {
  const dispatch = useDispatch();

  const zenrinApiKey = process.env.REACT_APP_ZENRIN_API_KEY;
  const zenrinApi = process.env.REACT_APP_ZENRIN_API;
  const [expandedCityCodes, setExpandedCityCodes] = useState([]);
  const [expandedAddressCodes, setExpandedAddressCodes] = useState([]);
  const [toggle, setToggle] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [lastTitle, setLastTitle] = useState();
  const [expandedKeysArray, setExpandedKeysArray] = useState([]);

  const cityCodeData = PREFECTURE_CODES.map((prefecture, index) => ({
    title: prefecture.value,
    key: `0-0-${index}`,
  }));

  useEffect(() => {
    setTreeData([
      {
        title: jpText.NATIONAL,
        key: "0-0",
        disabled: true,
        children: cityCodeData.map((city, index) => ({
          ...city,
          disabled: true,
          children: [
            {
              title: `${jpText.LOADING}...`,
              key: `0-0-${index}-0`,
              disabled: true,
              position: [appData.defaultLatLng.lng, appData.defaultLatLng.lat],
            },
          ],
        })),
      },
    ]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const [updateKey, setUpdateKey] = useState(0);

  useEffect(() => {
    const fetchDataForCity = async (cityCode) => {
      const getCode = cityCode?.code;
      const url = `${zenrinApi}/zips/general/address?&zis_authtype=ip&zis_authkey=${zenrinApiKey}&address_code=${getCode}&code_match_type=2&sort=address_code&datum=JGD&limit=0%2C500&show_code_zero=true&address_level=SHK`;
      try {
        const response = await fetch(url);
        const data = await response.json();

        const childrenData = data.result.item.map((item, index) => ({
          title: item.address3,
          key: item.address_code,
          position: item.position,
          children: [
            {
              title: `${jpText.LOADING}...`,
              key: `0-0-0-${index}-0`,
              disabled: true,
              position: [appData.defaultLatLng.lng, appData.defaultLatLng.lat],
            },
          ],
        }));

        setTreeData((prevTreeData) => {
          return prevTreeData.map((node) => {
            if (node.title === jpText.NATIONAL) {
              const updatedChildren = node.children.map((cityNode) => {
                if (cityNode.title === cityCode.value) {
                  return {
                    ...cityNode,
                    children:
                      childrenData.length > 0
                        ? childrenData
                        : [
                            {
                              title: `${jpText.NO_DATA_FOUND}...`,
                              key: `0-0-${cityCode.value}-0`,
                              disabled: true,
                              position: [
                                appData.defaultLatLng.lng,
                                appData.defaultLatLng.lat,
                              ],
                            },
                          ],
                  };
                }
                return cityNode;
              });

              return {
                ...node,
                children: updatedChildren,
              };
            }
            return node;
          });
        });
      } catch (error) {
        console.error(
          `${jpText.ERROR_FETCHING_DATA_FOR_CITY}:`,
          cityCode,
          error
        );
      }
    };

    const fetchDataForAddress = async (addressCode) => {
      const getCode = addressCode?.code;
      const getLevel = addressCode?.level;
      const url = `${zenrinApi}/zips/general/address?&zis_authtype=ip&zis_authkey=${zenrinApiKey}&address_code=${getCode}&code_match_type=2&sort=address_code&datum=JGD&limit=0%2C500&show_code_zero=true&address_level=${getLevel}`;
      try {
        const response = await fetch(url);
        const data = await response.json();
        if (data.result.item?.length > 0) {
          TreeDataFunction(
            addressCode,
            data,
            setTreeData,
            getLevel,
            treeData
          );
        } else {
          function filterAndReplaceChildren(array) {
            function traverse(children) {
              children.forEach((child) => {
                if (
                  child.children &&
                  child.children.length === 1 &&
                  child.title === lastTitle &&
                  child.children[0].title === "読み込み中..."
                ) {
                  child.children[0].title = jpText.NO_DATA;
                } else if (child.children && child.children.length > 1) {
                  traverse(child.children);
                }
              });
            }
            traverse(array);

            return array;
          }

          const filteredArray = filterAndReplaceChildren(treeData);
          setTreeData(filteredArray);

          setUpdateKey(updateKey + 1);
        }
      } catch (error) {}
    };

    if (!toggle) {
      expandedCityCodes.forEach((cityCode) => {
        fetchDataForCity(cityCode);
      });
    }

    if (toggle) {
      const addressCode = expandedAddressCodes[expandedAddressCodes.length - 1];
      fetchDataForAddress(addressCode);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expandedCityCodes, expandedAddressCodes]);

  const [mainTree, setMainTree] = useState(false);
  const handleExpand = (expandedKeys, { node }) => {
    if (node?.title === jpText.NATIONAL) {
      if (mainTree) {
        const emptyExpandedKeys = [];
        setExpandedKeysArray(emptyExpandedKeys);
        setMainTree(false);
        return;
      } else {
        setMainTree(true);
      }
    }
    setExpandedKeysArray(expandedKeys);
    let filterExpandedKeys = [];
    if (
      String(expandedKeys[expandedKeys.length - 1]).includes("-") &&
      expandedKeys.length > 2
    ) {
      filterExpandedKeys = ["0-0", expandedKeys[expandedKeys.length - 1]];
      setExpandedKeysArray(filterExpandedKeys);
    } else {
      let lastHyphenIndex = -1;
      for (let i = expandedKeys.length - 1; i >= 0; i--) {
        if (expandedKeys[i].includes("-")) {
          lastHyphenIndex = i;
          break;
        }
      }
      if (lastHyphenIndex !== -1) {
        filterExpandedKeys = expandedKeys.slice(lastHyphenIndex);
        setExpandedKeysArray(["0-0", ...filterExpandedKeys]);
        setExpandedAddressCodes([]);
      }
    }

    const expandedIndices = [];
    filterExpandedKeys.forEach((key) => {
      const dashCount = key.split("-").length - 1;
      if (dashCount === 2) {
        setToggle(false);
        const lastIndex = key.split("-").pop();
        expandedIndices.push(lastIndex);

        const cityCodes = expandedIndices.map(
          (index) => PREFECTURE_CODES[index]
        );
        setExpandedCityCodes(cityCodes);
      }

      if (key.indexOf("-") === -1) {
        setToggle(true);
        const addressLevel = getAddressLevelFromExpand(key);
        setExpandedAddressCodes([
          ...expandedAddressCodes,
          { code: key, level: addressLevel, title: node.title },
        ]);
        setLastTitle(node.title);
      }
    });
  };

  const onSelect = (selectedKeys, info) => {
    if (info?.node?.position?.length > 1) {
      const lat = info?.node?.position[1];
      const lng = info?.node?.position[0];

      dispatch(
        saveSelectedCityLoc({
          selectedCityLoc: {
            lat,
            lng,
          },
        })
      );

      setIsOpen(false);
    }
  };

  return (
    <div>
      <Tree
        key={updateKey}
        showLine={true}
        showIcon={false}
        defaultExpandedKeys={["0-0-0"]}
        expandedKeys={expandedKeysArray}
        onExpand={handleExpand}
        onSelect={onSelect}
        treeData={treeData}
        blockNode
      />
    </div>
  );
};

const getAddressLevelFromExpand = (key) => {
  if (key.length === ADDRESS_LEVEL.LENGTH_5) {
    return ADDRESS_LEVEL.OAZ;
  } else if (key.length === ADDRESS_LEVEL.LENGTH_8) {
    return ADDRESS_LEVEL.AZC;
  } else if (key.length === ADDRESS_LEVEL.LENGTH_11) {
    return ADDRESS_LEVEL.GIK;
  } else if (key.length === ADDRESS_LEVEL.LENGTH_16) {
    return ADDRESS_LEVEL.TBN;
  }
};
