import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isCompassDisplayToggled: true,
  isCenterPositionToggled: true,
};

export const toggleButtonSlice = createSlice({
  name: "toggleButtons",
  initialState,
  reducers: {
    toggleCompassDisplay: (state) => {
      state.isCompassDisplayToggled = !state.isCompassDisplayToggled;
    },
    toggleCenterPosition: (state) => {
      state.isCenterPositionToggled = !state.isCenterPositionToggled;
    },
    setCompassDisplay: (state, action) => {
      state.isCompassDisplayToggled = action.payload;
    },
    setCenterPosition: (state, action) => {
      state.isCenterPositionToggled = action.payload;
    },
  },
});

export const {
  toggleCompassDisplay,
  toggleCenterPosition,
  setCompassDisplay,
  setCenterPosition,
} = toggleButtonSlice.actions;

export default toggleButtonSlice.reducer;
